/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl, KTSVG} from '../../../helpers'
import { Link } from 'react-router-dom'

type Props = {
  color?: string
  logoUrl: string
  name: string
  description: string
}

const IntegrationCard: FC<Props> = ({
  color,
  logoUrl,
  name,
  description
}) => {
  return (
    <div className='card text-dark'>
      <div className='card-body d-flex flex-center flex-column p-9'>
        <div className='mb-5'>
          <div className='symbol symbol-75px'>
            {color ? (
              <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                {name.charAt(0)}
              </span>
            ) : (
              <img alt={name + ' Logo'} src={toAbsoluteUrl(logoUrl)} />
            )}
          </div>
        </div>

        <div className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
          {name}
        </div>

        <div className='fw-bold text-gray-400 mx-8 mt-3 mb-6 text-center'>{description}</div>

        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder'>{207}</div>
            <div className='fw-bold text-gray-400'>Active Users</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder'>{81}</div>
            <div className='fw-bold text-gray-400'>Startups</div>
          </div>
        </div>

        <Link to='#' className='btn btn-sm btn-light'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
          Connect
        </Link>
      </div>
    </div>
  )
}

export {IntegrationCard}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Card2} from '../../../../_metronic/partials/content/cards/Card2'
import { IconUserModel } from '../../browse-startups/StartupModels'
import { Card3 } from '../../../../_metronic/partials/content/cards/Card3'
import { IntegrationCard } from '../../../../_metronic/partials/content/cards/IntegrationCard'
import { Link } from 'react-router-dom'

export function IntegrationsList() {
  return (
    <>
      <div className='row g-6 g-xl-9'>

        <div className='col-md-6 col-xl-4'>
          <Link to='telegram'>
          <IntegrationCard
            name='Telegram'
            logoUrl='https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg'
            description='Create deposits and participate in discussions through your favorite messenger'
          />
          </Link>
        </div>
        
        <div className='col-md-6 col-xl-4'>
          <Link to='telegram'>
          <IntegrationCard
            name='LinkedIn'
            logoUrl='https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg'
            description='Create job postings and automate LinkedIn posts'
          />
          </Link>
        </div>

      </div>

      <div className='d-flex flex-stack flex-wrap pt-10'>
        <div className='fs-6 fw-bold text-gray-700'>Showing 1 to 2 of 2 entries</div>

        <ul className='pagination'>
          <li className='page-item previous'>
            <a href='#' className='page-link'>
              <i className='previous'></i>
            </a>
          </li>

          <li className='page-item active'>
            <a href='#' className='page-link'>
              1
            </a>
          </li>

          <li className='page-item next'>
            <a href='#' className='page-link'>
              <i className='next'></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

const users1: Array<IconUserModel> = [
  {name: 'Emma Smith', logo: '/media/logos/300-6.jpg'},
  {name: 'Rudy Stone', logo: '/media/logos/300-1.jpg'},
  {name: 'Susan Redwood'},
]

const users2 = [
  {name: 'Alan Warden'},
  {name: 'Brian Cox', logo: '/media/logos/300-5.jpg'},
]

const users3 = [
  {name: 'Mad Masy', logo: '/media/logos/300-6.jpg'},
  {name: 'Cris Willson', logo: '/media/logos/300-1.jpg'},
  {name: 'Mike Garcie'},
]

const users4 = [
  {name: 'Nich Warden'},
  {name: 'Rob Otto'},
]

const users5 = [
  {name: 'Francis Mitcham', logo: '/media/logos/300-20.jpg'},
  {name: 'Michelle Swanston', logo: '/media/logos/300-7.jpg'},
  {name: 'Susan Redwood'},
]

const users6 = [
  {name: 'Emma Smith', logo: '/media/logos/300-6.jpg'},
  {name: 'Rudy Stone', logo: '/media/logos/300-1.jpg'},
  {name: 'Susan Redwood'},
]

const users7 = [
  {name: 'Meloday Macy', logo: '/media/logos/300-2.jpg'},
  {name: 'Rabbin Watterman'},
]

const users8 = [
  {name: 'Emma Smith', logo: '/media/logos/300-6.jpg'},
  {name: 'Rudy Stone', logo: '/media/logos/300-1.jpg'},
  {name: 'Susan Redwood'},
]

const users9 = [
  {name: 'Meloday Macy', logo: '/media/logos/300-2.jpg'},
  {name: 'Rabbin Watterman'},
]
